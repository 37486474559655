import { render, staticRenderFns } from "./AdminTab.vue?vue&type=template&id=0657d78d&"
import script from "./AdminTab.vue?vue&type=script&lang=js&"
export * from "./AdminTab.vue?vue&type=script&lang=js&"
import style0 from "@/assets/sass/components/views/admin/admin-tabs.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports